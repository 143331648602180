import { formatPrice } from '@storefront/util/prices';
import clsx from 'clsx';
import type { FC } from 'react';
import { DiscountBadge } from './DiscountBadge';

export interface DiscountedPriceLabelProps {
  className?: string;
  discountedPrice?: number | null;
  originalPrice: number;
  percentageDiscount?: number;
  hasDiscountBiggerThan30Percent?: boolean;
  currency: Intl.NumberFormatOptions['currency'];
}

export const DiscountedPriceLabel: FC<DiscountedPriceLabelProps> = ({
  className,
  currency,
  discountedPrice,
  percentageDiscount,
  hasDiscountBiggerThan30Percent,
  originalPrice,
}) => {
  if (!discountedPrice) {
    return (
      <div className={clsx('discounted-price-label', className)}>
        <div className={clsx('text-2xl font-bold text-gray-700', className)}>
          {formatPrice(originalPrice, { currency })}
        </div>
      </div>
    );
  }
  return (
    <div className={clsx('discounted-price-label space-y-1', className)}>
      <div className={clsx('text-gray-700 line-through text-xs sm:text-base')}>
        {formatPrice(originalPrice, { currency })}
      </div>
      <div
        className={clsx(
          'flex align-middle items-center text-medium sm:text-3xl font-bold text-gray-700 gap-[6px]',
          className,
        )}
      >
        <div>{formatPrice(discountedPrice, { currency })}</div>
        {hasDiscountBiggerThan30Percent && percentageDiscount && (
          <DiscountBadge percentageDiscount={percentageDiscount} />
        )}
      </div>
    </div>
  );
};
